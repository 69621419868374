import { Input } from 'componentsV2/elements/input';
import { useUpdateLead } from 'hooksV2/useUpdateLead';
import { debounce } from 'lodash';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { useEffect, useState } from 'react';

export const LeadDetailsPipeValue = (): JSX.Element => {
  const { lead, leadType } = useLeadDetailsContext();

  const { mutateAsync: updateLead } = useUpdateLead();

  const [pipeValue, setPipeValue] = useState<string | undefined>(
    lead?.pipeValue?.toString() || undefined
  );

  useEffect(() => {
    if (lead) setPipeValue(lead.pipeValue?.toString());
  }, [lead]);

  useEffect(() => {
    const handleDebouncedUpdatePipeValue = debounce(() => {
      updateLead({
        type: leadType,
        leadId: lead?.id as string,
        pipeValue,
      });
    }, 500);

    if (pipeValue !== lead?.pipeValue?.toString()) {
      handleDebouncedUpdatePipeValue();
    }

    return () => {
      handleDebouncedUpdatePipeValue.cancel();
    };
  }, [pipeValue]);

  return (
    <Input.Root w="120px" fontSize="0.8rem">
      <Input.Currency
        placeholder="Pipe"
        value={pipeValue}
        onValueChange={value => setPipeValue(value?.split(',')[0])}
      />
    </Input.Root>
  );
};
