import { ModalWrapper } from 'components/elements/wrappers';
import { DeclineLeadOfferModal } from 'componentsV2/modules/captation/declineLeadOfferModal';
import { useQueryParams } from 'hooksV2/useQueryParams';
import { useLeadDetailsContext } from 'pagesV2/LeadDetails/context/useLeadDetailsContext';
import { SecondaryButton } from '../../../../components/elements/buttons';

export const LeadDetailsDeclineOffer = (): JSX.Element => {
  const { lead, leadType } = useLeadDetailsContext();

  const { getParam, setParam, deleteParam } = useQueryParams();

  const isOpenDeclineOfferModal =
    getParam('isOpenDeclineOfferModal') === 'true';

  return (
    <>
      <SecondaryButton
        onClick={() => setParam('isOpenDeclineOfferModal', 'true')}
        fontSize="0.8rem"
      >
        Declinio da Oferta
      </SecondaryButton>

      <ModalWrapper
        isOpen={isOpenDeclineOfferModal}
        onClose={() => deleteParam('isOpenDeclineOfferModal')}
      >
        <DeclineLeadOfferModal
          lead={lead}
          shouldOnlyDeclineMeeting={!!lead?.meeting}
          leadType={leadType}
        />
      </ModalWrapper>
    </>
  );
};
